import '../styles/global.scss';
import '../styles/pages/privacy.scss';

import {HeadFC, Link} from "gatsby";

import {
    BigLogoImage
} from "../images";
import React, {useState} from 'react'
import MainFooter from '../components/MainFooter';

const Privacy = () => {

    return (
        <>
            <div className="DocPage">
                <div className="DocPageLogo">
                    <Link to="/">
                        <img src={BigLogoImage} width={200} alt=""/>
                    </Link>
                </div>
                <div className="DocPageWrapper">
                    <h2>MONEY-BACK POLICY</h2>

                    <h3>I. MONEY-BACK GUARANTEE RULES</h3>
                    <p>In addition to refund rights available under applicable laws, if you purchased our app directly on our websites and the money-back option was presented to you during the checkout, you are eligible to receive a refund if you did like our
                    app, provided that all of the following conditions are met: <br />
                    ● you contact us within 30 days after your initial purchase and before the end of your subscription period; and<br />
                    ● you have followed our program (i) at least during 7 consecutive days within the first 30 days after the purchase (for monthly and more lengthy subscription periods) or (ii) at least during 3 consecutive days within the first 7 days after
                    the purchase (for weekly and biweekly subscription periods); and<br />
                    ● you are able to demonstrate that you have followed the program pursuant to the requirements stated below in Section “How to demonstrate that you have followed the program”.<br /><br />
                    We will review your application and notify you (by email or otherwise) whether your application is approved.</p>

                    <h3>HOW TO DEMONSTRATE THAT YOU HAVE FOLLOWED THE PROGRAM</h3>
                    <p>You can demonstrate that you have followed the program by fulfilling the following simple condition: <br />
                    (1) you provide screenshots from the app proving that you have finished<br />
                    a. at least 7 workout/meditation or other sessions (for monthly and more lengthy subscription periods); or<br />
                    b. at least 3 workout/meditation or other sessions (for weekly and biweekly subscription periods).</p>
                    <h3>IMPORTANT STATEMENT</h3>
                    <p>Please note that only fulfillment of the above requirements allows you to receive a refund under the Money-back Guarantee. For the sake of clarity, this Money-back Guarantee does not apply to any other instances.</p>

                    <h3>II. GENERAL REFUND RULES</h3>
                    <p>We value any feedback and do everything to make our customers happy with our products and services. However, if
                    you are not completely satisfied with our services, you can get a refund according to the terms provided herein. <br /><br />

                    Generally, if you do not meet the conditions of our Money-back Guarantee set out above, the fees you have paid are
                    non-refundable and/or non-exchangeable, unless otherwise is stated herein or as required by applicable law. In
                    addition, certain refund requests may be considered by our company on a case-by-case basis and granted at our sole
                    discretion. <br /><br />

                    A refund can usually be claimed only during the subscription period. If the subscription period has expired before you made a request for a refund, we will not be able to provide you with a refund. <br /><br />
                    Note for residents of certain US states. If you reside in California or Connecticut and cancel the purchase at any
                    time prior to midnight of the third business day after the date of such purchase, we will return the payment you have made. <br /><br />
                    Note for the EU residents: If you are an EU resident, you have the right to withdraw from the agreement for purchase
                    of digital content without charge and without giving any reason within fourteen (14) days from the date of such
                    agreement conclusion. The withdrawal right does not apply if the performance of the agreement has begun with your
                    prior express consent and your acknowledgment that you thereby lose your right of withdrawal. YOU HEREBY
                    EXPRESSLY CONSENT TO THE IMMEDIATE PERFORMANCE OF THE AGREEMENT AND ACKNOWLEDGE
                    THAT YOU WILL LOSE YOUR RIGHT OF WITHDRAWAL FROM THE AGREEMENT ONCE OUR SERVERS
                    VALIDATE YOUR PURCHASE AND THE APPLICABLE PURCHASE IS SUCCESSFULLY DELIVERED TO YOU.
                    Therefore, you will not be eligible for a refund, unless the digital content is defective. <br /><br />
                    Any translation from the English version is provided for your convenience only. In the event of any difference in meaning, version, or interpretation between the English language version of this Money-back Policy and any
                    translation, the English language version will prevail.</p>
                </div>	
            </div>
            <MainFooter />
        </>
    )
}

export default Privacy;

export const Head: HeadFC = () => (
    <>
        <title>Money back policy - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
